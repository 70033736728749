<script>
  import { Router, Route } from 'svelte-routing'
  import Home from './routes/home/index.svelte'
  import About from './routes/about/index.svelte'
  import Comics from './routes/comics/index.svelte'

  export let url = ''
</script>

<Router {url}>
  <Route path="/" component={Home} />
  <Route path="/comics" component={Comics} />
  <Route path="/about" component={About} />
</Router>
