<script>
  import Logo from '../shared/Logo.svelte'
  import InstagramLogo from '../icons/Instagram.svelte'
  import CaraLogo from '../icons/Cara.svelte'
  import ArtStationLogo from '../icons/ArtStation.svelte'
  import MailLogo from '../icons/Mail.svelte'
  import { navigate } from 'svelte-routing'
  let showMenu = false
  export let showSocials = false

  export function goto(input) {
    showMenu = false
    setTimeout(() => navigate(input), 500)
  }

  export function accordion(node, show) {
    let initialHeight = 0
    let finalHeight = 20
    node.style.height = show ? finalHeight : initialHeight
    node.style.overflow = 'hidden'
    return {
      update(isOpen) {
        let animation = node.animate(
          [
            {
              height: finalHeight + 'em',
              overflow: 'hidden',
            },
            {
              height: initialHeight + 'em',
              overflow: 'hidden',
            },
          ],
          { duration: 200, fill: 'both', easing: 'ease-in-out' }
        )
        animation.pause()
        if (!isOpen) {
          animation.play()
        } else {
          animation.reverse()
        }
      },
    }
  }
</script>

<div>
  <div class="menu" use:accordion={showMenu}>
    <button on:click={() => goto('/')}>Illustrations</button>
    <button on:click={() => goto('/comics')}>Comics</button>
    <button on:click={() => goto('/about')}>About</button>
  </div>

  <header>
    <div class="right-corner">
      <Logo />
      {#if showSocials}
        <div class="media">
          <div>
            <InstagramLogo height="1.5em" />
          </div>
          <div>
            <ArtStationLogo height="1.5em" />
          </div>
          <div>
            <CaraLogo height="1.5em" />
          </div>
          <div>
            <MailLogo height="1.5em" />
          </div>
        </div>
      {/if}
    </div>

    <div class="left-corner">
      <div class="navbar">
        <button on:click={() => navigate('/')}>Illustrations</button>
        <button on:click={() => navigate('/comics')}>Comics</button>
        <button on:click={() => navigate('/about')}>About</button>
      </div>
      <div class="sandwich">
        <button on:click={() => (showMenu = !showMenu)}>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>
    </div>
  </header>
</div>

<style>
  header {
    padding: 1em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-family: RedHat;
  }

  .navbar button {
    margin-left: 1em;
    border: none;
    color: #000;
    background-color: transparent;
    text-align: right;
    font-size: 1.5rem;
    font-family: RedHat;
    font-weight: 300;

    @media (max-width: 1000px) {
      font-size: 1.3rem;
    }
  }

  .navbar {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .menu {
    width: 100%;
    height: 20em;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: height 0.5s;
  }

  .menu button {
    color: #fff;
    text-align: right;
    font-size: 1.5rem;
    font-family: RedHat;
    font-weight: 300;
  }

  .right-corner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .left-corner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left-corner:last-child {
    margin-right: 1em;
  }

  .media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.4em;
    @media (min-width: 600px) {
      display: none;
    }
  }

  .media div {
    margin-right: 0.5em;
  }

  .media div:last-child {
    margin-right: 0;
  }

  .sandwich div {
    background-color: #ccc;
    border-radius: 5px;
    width: 2.5em;
    height: 0.3em;
    margin: 0.2em 0;

    @media (min-width: 600px) {
      display: none;
    }
  }
</style>
