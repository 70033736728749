<script>
  import { onMount } from 'svelte'
  import Header from '../../shared/header.svelte'
  import Footer from '../../shared/footer.svelte'
  import Overlay from '../../shared/overlay.svelte'
  import { illustrations } from '../../constants/illustrations'
  import ' ./styles.svelte'

  let container
  let overlayImageIndex = null
  let changeIllustrationIndex = (index) => {
    overlayImageIndex = index
    console.log(index)
  }

  onMount(() => {
    const handleScroll = (e) => {
      if (window.innerWidth <= 600) return
      e.preventDefault()
      container.scrollLeft += e.deltaY
    }

    container.addEventListener('wheel', handleScroll)

    return () => {
      container.removeEventListener('wheel', handleScroll)
    }
  })
</script>

<main>
  <Header showSocials />

  <div style={overlayImageIndex === null ? 'display: none' : 'display: flex'}>
    <Overlay
      {overlayImageIndex}
      {illustrations}
      onClose={() => changeIllustrationIndex(null)}
      {changeIllustrationIndex}
    />
  </div>

  <div class="container" bind:this={container}>
    {#each illustrations as illustration, index}
      <div class="card" key={index}>
        <img
          src={illustration.path_webp}
          alt={illustration.description}
          on:click={() => changeIllustrationIndex(index)}
          on:keypress={() => changeIllustrationIndex(index)}
        />
        <div class="card-info">
          <h1>{illustration.name}</h1>
          <p>{illustration.description}</p>
          <p>{illustration.year}</p>
        </div>
      </div>
    {/each}
  </div>

  <Footer />
</main>

<style lang="css" src="./styles.css"></style>
