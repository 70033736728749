let minified_dir = 'images/comics/webp';
let png_dir = 'images/comics/png';
export const comics = [
    {
        name: 'Cortes',
        year: '2021',
        descriptionPT: 'Publicado em Meia Cura 2 (2022), organizado por João Agreli, Rosemário Souza e Alexandre Carvalho, e realizado através do PMIC em Uberlândia, MG. Cortes é um quadrinho de terror sobrenatural sobre um monstro, uma instituição misteriosa e um passado sombrio.',
        descriptionEN: 'Published in Meia Cura 2 (2022), organized by João Agreli, Rosemário Souza and Alexandre Carvalho, and realized through PMIC in Uberlândia, MG. Cortes is a thriller supernatural comic about a monster, a mysterious institution and a dark past.',
        showLink: false,
        pages: [
            {
                path_webp: `${minified_dir}/cura_1.webp`,
                path_png: `${png_dir}/cura_1.png`,
                page: 1,
            },
            {
                path_webp: `${minified_dir}/cura_2.webp`,
                path_png: `${png_dir}/cura_2.png`,
                page: 2,
            },
            {
                path_webp: `${minified_dir}/cura_3.webp`,
                path_png: `${png_dir}/cura_3.png`,
                page: 3,
            },
            {
                path_webp: `${minified_dir}/cura_4.webp`,
                path_png: `${png_dir}/cura_4.png`,
                page: 4,
            },
            {
                path_webp: `${minified_dir}/cura_5.webp`,
                path_png: `${png_dir}/cura_5.png`,
                page: 5,
            },
            {
                path_webp: `${minified_dir}/cura_6.webp`,
                path_png: `${png_dir}/cura_6.png`,
                page: 6,
            },
            {
                path_webp: `${minified_dir}/cura_7.webp`,
                path_png: `${png_dir}/cura_7.png`,
                page: 7,
            },
            {
                path_webp: `${minified_dir}/cura_8.webp`,
                path_png: `${png_dir}/cura_8.png`,
                page: 8,
            },
            {
                path_webp: `${minified_dir}/cura_9.webp`,
                path_png: `${png_dir}/cura_9.png`,
                page: 9,
            },
        ],
    },
    {
        name: 'Arca',
        year: '2023',
        descriptionPT: 'Publicado online, Arca é uma história sobre um host de site em encerramento, contada através de páginas de quadrinhos e sites diegéticos fictícios. Todos os quadrinhos e códigos foram feitos pelo autor, e o projeto foi um requisito parcial para uma tese final de conclusão de curso. Foi publicado no Neocities e pode ser acessado na íntegra',
        descriptionEN: 'Published online, Arca is a story about a closing site host, told through comic pages and fictional diegetic sites. All comics and coding were done by the author, and the project was a partial requirement for a final thesis. It was published on Neocities and can be accessed in full',
        link: 'https://arca.neocities.org/',
        pages: [
            {
                path_webp: `${minified_dir}/arca_1.webp`,
                path_png: `${png_dir}/arca_1.png`,
                page: 1,
            },
            {
                path_webp: `${minified_dir}/arca_2.webp`,
                path_png: `${png_dir}/arca_2.png`,
                page: 2,
            },
            {
                path_webp: `${minified_dir}/arca_3.webp`,
                path_png: `${png_dir}/arca_3.png`,
                page: 3,
            },
            {
                path_webp: `${minified_dir}/arca_4.webp`,
                path_png: `${png_dir}/arca_4.png`,
                page: 4,
            },
            {
                path_webp: `${minified_dir}/arca_5.webp`,
                path_png: `${png_dir}/arca_5.png`,
                page: 5,
            },
        ],
    },
    {
        name: 'Aquela Noite',
        year: '2022-2023',
        descriptionPT: 'Um webcomic de terror curto sobre adolescentes lidando com horrores, tanto internos quanto externos, em uma floresta escura. Toda a escrita, desenhos e codificação foram feitos e organizados pelo autor. Está hospedado no Neocities e totalmente acessível',
        descriptionEN: "A short horror webcomic about teens grappling with horrors, both internal and external, in dark woods. All writing, drawings and coding were done and organized by the author. It's hosted on Neocities and fully accessible",
        link: 'https://aquelanoite.neocities.org/',
        pages: [
            {
                path_webp: `${minified_dir}/noite_1.webp`,
                path_png: `${png_dir}/noite_1.png`,
                page: 1,
            },
            {
                path_webp: `${minified_dir}/noite_2.webp`,
                path_png: `${png_dir}/noite_2.png`,
                page: 2,
            },
            {
                path_webp: `${minified_dir}/noite_3.webp`,
                path_png: `${png_dir}/noite_3.png`,
                page: 3,
            },
        ],
    },
];
