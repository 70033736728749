<script>
  export let lang = 'EN'
  export let onSelect = () => {}
</script>

<div class="language-button">
  <button
    on:click={() => {
      console.log('...')
      onSelect('EN')
    }}
  >
    <p1 class:selected={lang === 'EN'}>EN</p1>
  </button>
  <p>/</p>
  <button on:click={() => onSelect('PT')}>
    <p1 class:selected={lang === 'PT'}>PT</p1>
  </button>
</div>

<style>
  .language-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
  }

  .language-button p {
    margin: 0.5em;
  }

  .selected {
    font-weight: 800;
  }
</style>
