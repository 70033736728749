<script>
  import Header from '../../shared/header.svelte'
  import Footer from '../../shared/footer.svelte'
  import Comics from '../../shared/comicsReader.svelte'
  import LanguageButton from '../../shared/languageButton.svelte'
  import { comics } from '../../constants/comics.ts'

  let comicsIndexes = [0, -1, 0]
  let language = 'EN'
</script>

<main>
  <Header />

  <div class="container">
    {#each comics as comic, index}
      <div class="section">
        <div class="info">
          <div class="description">
            <div class="title-line">
              <h1>{comic.name}</h1>
              <p>{comic.year}</p>
            </div>
            <LanguageButton
              lang={language}
              onSelect={(lang) => (language = lang)}
            />
            <p>
              {comic['description' + language]}
              {#if comic.link}
                <u>
                  <a href={comic.link} target="_blank">
                    {language == 'EN' ? 'here' : 'aqui'}
                  </a>
                </u>.
              {/if}
            </p>
          </div>
          <div class="mini-map">
            {#if comic.name === 'Arca'}
              <div
                key={-1}
                class={-1 === comicsIndexes[index]
                  ? 'selected'
                  : 'mini-map-item'}
                on:click={() => (comicsIndexes[index] = -1)}
                on:keypress={() => (comicsIndexes[index] = -1)}
              >
                <div class="page_number">0</div>
                <iframe
                  id="arca_iframe"
                  title="arca"
                  src="https://arca.neocities.org/"
                  style="width: 100%; height: 100%;"
                ></iframe>
              </div>
            {/if}
            {#each comic.pages as page, i}
              <div
                key={i}
                class={i === comicsIndexes[index]
                  ? 'selected'
                  : 'mini-map-item'}
                on:click={() => (comicsIndexes[index] = i)}
                on:keypress={() => (comicsIndexes[index] = i)}
              >
                <div class="page_number">{i + 1}</div>
                <img src={page.path_webp} alt={page.name} />
              </div>
            {/each}
          </div>
        </div>

        {#if comic.name === 'Arca'}
          <Comics
            useCover
            comicsPages={comic.pages}
            index={comicsIndexes[index]}
            }
          >
            <iframe
              id="arca_iframe"
              title="arca"
              src="https://arca.neocities.org/"
            ></iframe>
          </Comics>
        {:else}
          <Comics comicsPages={comic.pages} index={comicsIndexes[index]} />
        {/if}
      </div>
    {/each}
  </div>
  <Footer />
</main>

<style lang="css" src="./styles.css"></style>
