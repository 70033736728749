let minified_dir = 'images/illustration/webp';
let png_dir = 'images/illustration/png';
export const illustrations = [
    {
        path_webp: `${minified_dir}/outlook.webp`,
        path_png: `${png_dir}/outlook.png`,
        name: 'Outlook',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/futures_thief_ghost_of_past.webp`,
        path_png: `${png_dir}/futures_thief_ghost_of_past.png`,
        name: "Future's thief, ghost of Past",
        description: 'Illustration for the Secret Samol event. Fanart of Friends at the Table.',
        year: '2023',
    },
    {
        path_webp: `${minified_dir}/aboleth_fight.webp`,
        path_png: `${png_dir}/aboleth_fight.png`,
        name: 'Aboleth Fight',
        description: 'Personal work.',
        year: '2022',
    },
    {
        path_webp: `${minified_dir}/cloud.webp`,
        path_png: `${png_dir}/cloud.png`,
        name: 'Cloud',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/seeking_warmth.webp`,
        path_png: `${png_dir}/seeking_warmth.png`,
        name: 'Seeking Warmth',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/quaint.webp`,
        path_png: `${png_dir}/quaint.png`,
        name: 'Quaint',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/we_are_almost.webp`,
        path_png: `${png_dir}/we_are_almost.png`,
        name: 'We are almost there',
        description: 'Illustration for the Secret Samol event. Fanart of Friends at the Table.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/rest.webp`,
        path_png: `${png_dir}/rest.png`,
        name: 'Rest',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/ymo.webp`,
        path_png: `${png_dir}/ymo.png`,
        name: 'Ymo',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/guardians.webp`,
        path_png: `${png_dir}/guardians.png`,
        name: 'Guardians',
        description: 'Personal work.',
        year: '2022',
    },
    {
        path_webp: `${minified_dir}/final_resting_place.webp`,
        path_png: `${png_dir}/final_resting_place.png`,
        name: 'Final resting place',
        description: 'Contribution to Fright!, a classic horror movie zine.',
        year: '2022',
    },
    {
        path_webp: `${minified_dir}/research.webp`,
        path_png: `${png_dir}/research.png`,
        name: 'Research',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/haunting.webp`,
        path_png: `${png_dir}/haunting.png`,
        name: 'Haunting',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/field_repairs.webp`,
        path_png: `${png_dir}/field_repairs.png`,
        name: 'Field repairs',
        description: 'Personal work.',
        year: '2024',
    },
    {
        path_webp: `${minified_dir}/on_watch.webp`,
        path_png: `${png_dir}/on_watch.png`,
        name: 'On Watch',
        description: 'Personal work.',
        year: '2023',
    },
    {
        path_webp: `${minified_dir}/king_of_hearts.webp`,
        path_png: `${png_dir}/king_of_hearts.png`,
        name: 'King of Hearts',
        description: 'Personal work, Wandersong fanart',
        year: '2022',
    },
];
