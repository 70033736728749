<script>
  import { LazyImage } from 'svelte-lazy-image'

  export let illustrations = []
  export let overlayImageIndex
  export let onClose
  export let changeIllustrationIndex
</script>

<div class="dimmed-bg" on:click={onClose} on:keypress={onClose} />

<div class="container">
  <div class="card">
    <button class="close" on:click={onClose} on:keypress={onClose}>close</button
    >
    <LazyImage
      src={illustrations[overlayImageIndex]?.path_png}
      placeholder={illustrations[overlayImageIndex]?.path_webp}
      alt={illustrations[overlayImageIndex]?.description}
      style="max-width: 90vw; max-height: 75vh; margin-bottom: 1em;"
    />
    <div class="row">
      <div class="info">
        <h1>{illustrations[overlayImageIndex]?.name}</h1>
        <p>{illustrations[overlayImageIndex]?.description}</p>
        <p>{illustrations[overlayImageIndex]?.year}</p>
      </div>
      <div class="controls">
        <button
          on:click={() => changeIllustrationIndex(overlayImageIndex - 1)}
          on:keypress={() => changeIllustrationIndex(overlayImageIndex - 1)}
          disabled={overlayImageIndex === 0}
        >
          {'< previous'}
        </button>
        <button
          on:click={() => changeIllustrationIndex(overlayImageIndex + 1)}
          on:keypress={() => changeIllustrationIndex(overlayImageIndex + 1)}
          disabled={overlayImageIndex === illustrations.length - 1}
        >
          {'next >'}
        </button>
      </div>
    </div>
  </div>
</div>

<style>
  .dimmed-bg {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  .container {
    z-index: 200;
    position: fixed;
    top: 0;
    pointer-events: none;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    max-width: 80%;
  }

  .close {
    align-self: flex-end;
    border: none;
    border-radius: 0 0 0 10px;
    cursor: pointer;
    font-size: 1em;
    margin-bottom: 0.5em;

    @media (max-width: 800px) {
      margin-bottom: 0.2em;
    }

    @media (max-width: 500px) {
      padding: 0 1em;
    }
  }

  .row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 500px) {
      padding: 0 1em;
    }
  }

  .controls {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1em;

    @media (max-width: 500px) {
      gap: 0.2em;
    }
  }

  .close {
    color: #777777;
    font-size: 1em;
  }

  .controls button {
    color: #777777;
    border: none;
    background-color: #fff;
    cursor: pointer;
    font-size: 1em;
  }

  h1 {
    font-size: 1.5em;
    color: #888888;

    @media (max-width: 800px) {
      font-size: 1.2em;
    }

    @media (max-width: 600px) {
      font-size: 1em;
    }

    @media (max-width: 500px) {
      font-size: 0.8em;
    }
  }

  p {
    font-size: 1em;
    color: #777777;
    max-width: 20em;

    @media (max-width: 800px) {
      max-width: 14em;
      font-size: 0.9em;
    }

    @media (max-width: 600px) {
      font-size: 0.8em;
    }

    @media (max-width: 500px) {
      font-size: 0.7em;
    }
  }

  .card {
    overflow: visible;
    position: relative;
    z-index: 200;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 1em 2em;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 800px) {
      max-width: 95vw;
      max-height: 95vh;
      padding: 0.5em 1em;
    }

    @media (max-width: 500px) {
      max-width: 100vw;
      max-height: 95vh;
      padding: 1em 0em;
    }
  }
</style>
