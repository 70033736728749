<script>
  import InstagramLogo from '../icons/Instagram.svelte'
  import CaraLogo from '../icons/Cara.svelte'
  import ArtStationLogo from '../icons/ArtStation.svelte'
  import MailLogo from '../icons/Mail.svelte'
  import DdLogo from '../icons/dd_horizontal.svelte'

  export let showDdLogo = false
</script>

<footer>
  <div class="info">
    <div class="media">
      <InstagramLogo />
      <ArtStationLogo />
      <CaraLogo />
      <MailLogo />
    </div>
    {#if showDdLogo}
      <div class="hide">
        <DdLogo fill="#000" opacity="0.3" height="2.5em" />
      </div>
    {/if}
    <p>
      © Copyright 2024 - Elias Martin Oliveira Ribeiro, all rights reserved
    </p>
  </div>
  {#if showDdLogo}
    <div class="hide">
      <DdLogo fill="#000" opacity="0.3" height="2.5em" />
    </div>
  {/if}
</footer>

<style>
  footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    font-family: RedHat;
    padding: 0 1em;
    padding-bottom: 1em;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 9em;

    margin-left: 1em;

    @media (max-width: 600px) {
      margin-left: 0;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 3em;
      text-align: center;
    }
  }

  .info .hide {
    display: none;

    @media (max-width: 600px) {
      padding-top: 1em;
      display: block;
      margin-top: 5em;
      margin-right: 0;
    }
  }

  .hide {
    margin-right: 2em;

    @media (max-width: 600px) {
      display: none;
    }
  }

  p {
    color: #777777;
    margin-top: 0.5em;
    text-align: right;

    @media (max-width: 600px) {
      text-align: center;
      margin-top: 1em;
    }
  }
</style>
