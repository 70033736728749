<script>
  export let height = '2em'
  export let fill = '#AAAAAA'
  function openCara() {
    window.open('https://cara.app/mnchrmalien')
  }
</script>

<button on:click={openCara}>
  <svg
    {height}
    viewBox="0 0 33 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0798 11.4746L32.2642 4.51845C24.0302 -1.99725 11.4536 -1.589 4.21345 6.54406C1.81395 9.23953 0.271238 12.756 0.0931446 17.0216C-0.154627 22.9562 2.51846 28.4524 6.68555 31.4027C14.5678 36.9827 25.1797 35.6361 32.7439 30.3463L28.1096 22.9795C22.5473 27.2938 12.3347 26.8827 11.6289 18.2541C10.9232 9.62571 20.4874 7.16038 27.0798 11.4746Z"
      {fill}
    />
  </svg>
</button>
