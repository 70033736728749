<script>
  export let comicsPages = []
  const SLOTS = $$slots

  export let index = -1
  console.log(comicsPages)
</script>

{#if index == -1 && SLOTS.default}
  <div class="page">
    <slot />
  </div>
{:else}
  <div class="page">
    <img
      src={comicsPages?.[index == -1 ? 0 : index]?.path_png}
      alt="aquela_noite"
    />
  </div>
{/if}

<style>
  .page {
    min-height: 40em;
    max-height: 80vh;
    overflow: hidden;

    display: flex;
    justify-content: flex-start;
    border-top: 1px solid #ddd;

    @media (max-width: 900px) {
      height: auto;
      width: 100%;
      background-color: #fff;
    }
  }

  .page img {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
    object-position: center top;
  }
</style>
