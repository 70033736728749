<script>
  export let height = '2em'
  export let fill = '#AAAAAA'
  export let mail = 'artofelias.m@gmail.com'
  function openMail() {
    window.open(`mailto:${mail}`)
  }
</script>

<button on:click={openMail}>
  <svg
    {height}
    viewBox="0 0 47 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_98_20)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9917 20.4181L24.1823 24.6509L30.1498 20.5341L43.5263 33.7313C43.1734 33.8465 42.8001 33.9048 42.4063 33.9062H5.94947C5.46822 33.9062 5.00885 33.8122 4.58666 33.6437L17.9917 20.4181ZM46.0529 9.5725V30.2597C46.0529 30.8 45.9348 31.3119 45.7248 31.7734L32.6129 18.8366L46.0529 9.5725ZM2.30291 9.68844L15.5198 18.7272L2.53478 31.5416C2.38267 31.1312 2.30418 30.6973 2.30291 30.2597L2.30291 9.68844ZM42.4063 1.09375C44.4188 1.09375 46.0529 2.72562 46.0529 4.74031V6.02219L24.1735 21.105L2.30291 6.14687V4.74031C2.30291 2.72781 3.93479 1.09375 5.94947 1.09375H42.4063Z"
        {fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_98_20">
        <rect
          width="45.9375"
          height="35"
          transform="translate(0.743927)"
          {fill}
        />
      </clipPath>
    </defs>
  </svg>
</button>
