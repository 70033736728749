<script>
  export let height = '2em'
  export let fill = '#AAAAAA'
  function openArtStation() {
    window.open('https://www.artstation.com/mnchrmalien')
  }
</script>

<button on:click={openArtStation}>
  <svg
    {height}
    viewBox="0 0 41 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.318604 26.9839L3.68321 32.7882C4.34005 34.1019 5.70734 35 7.2757 35H29.5679L24.9432 26.9839H0.318604Z"
      {fill}
    />
    <path
      d="M40.0773 27.0241C40.0773 26.2198 39.836 25.4691 39.4338 24.8525L26.3776 2.13136C25.7073 0.857907 24.3668 0 22.8253 0H15.9218L36.096 34.9597L39.273 29.4504C39.9164 28.3914 40.0773 27.9356 40.0773 27.0241Z"
      {fill}
    />
    <path d="M21.6457 21.2869L12.6242 5.65686L3.6028 21.2869H21.6457Z" {fill} />
  </svg>
</button>

<style>
  svg:hover {
    opacity: 0.4;
    transition: opacity 0.3s;
  }
</style>
