<script>
  export let fill1 = '#000000'
  export let fill2 = '#828282'

  export let mail = 'artofelias.m@gmail.com'
  export const onPress = () => {
    window.open(`mailto:${mail}`)
  }
</script>

<div on:click={() => onPress()} on:keypress={() => onPress()}>
  <div class="title">
    <h1 style:color={fill1}>Elias</h1>
    <h1 style:color={fill1}>Martin</h1>
  </div>
  <p style:color={fill2}>artofelias.m@gmail.com</p>
</div>

<style>
  div {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    background-color: #fff;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h1 {
    font-family: Domino;
    color: #000;
    font-size: 2.1rem;
    font-weight: 900;
    background-color: #fff;
    margin: 0;
  }
  p {
    font-family: RedHat;
    font-weight: 400;
    color: #828282;
    font-size: 1.15rem;
    background-color: #fff;
    margin: 0;
    margin-top: -0.2em;
  }
</style>
