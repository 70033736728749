<script>
  import Logo from './icons/Logo.svelte'
  import InstagramLogo from './icons/Instagram.svelte'
  import CaraLogo from './icons/Cara.svelte'
  import ArtStationLogo from './icons/ArtStation.svelte'
  import MailLogo from './icons/Mail.svelte'
</script>

<main>
  <header>
    <Logo />
    <div>
      <button>Illustrations</button>
      <button>Comics</button>
      <button>About</button>
      <button>Blog</button>
    </div>
  </header>

  <footer>
    <div class="media">
      <InstagramLogo />
      <ArtStationLogo />
      <CaraLogo />
      <MailLogo />
    </div>
    <p>© Elias Martin Oliveira Ribeiro, all rights reserved</p>
  </footer>
</main>

<style>
  main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }

  header {
    padding: 1em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    text-align: center;
    font-family: RedHat;
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #aaaaaa;
    background-color: #000000;
    text-align: center;
    font-family: RedHat;
  }

  button {
    margin-left: 1em;
    border: none;
    color: #000;
    background-color: transparent;
    text-align: right;
    font-size: 1.5rem;
    font-family: RedHat;
    font-weight: 300;
  }

  p {
    color: #aaaaaa;
  }

  .media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 9em;
    margin-bottom: 0.5em;
  }
</style>
