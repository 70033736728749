<script>
  import Header from '../../shared/header.svelte'
  import Footer from '../../shared/footer.svelte'

  let selectedLanguage = 'EN'
</script>

<main>
  <Header />

  <div class="content">
    <img src="/images/profile_pic.webp" alt="profile pic" />
    <div>
      <div class="language-button">
        <button on:click={() => (selectedLanguage = 'EN')}>
          <p1 class:selected={selectedLanguage === 'EN'}>EN</p1>
        </button>
        <p>/</p>
        <button on:click={() => (selectedLanguage = 'PT')}>
          <p1 class:selected={selectedLanguage === 'PT'}>PT</p1>
        </button>
      </div>
      {#if selectedLanguage === 'EN'}
        <p>
          <b>Elias Martin</b> is a northeastern brazilian artist seeking work in
          the field of book covers and tabletop games, with previous experience in
          comics, visual development and character design. He has a Visual Arts degree
          and published works in various anthologies, as well as self-published stories
          that are available online.
        </p>
        <p>
          He is a lover of speculative fiction, tabletop RPGs and all things
          peculiar and strange, also dogs. He enjoys working on narrative-driven
          projects and detail-oriented roles.
        </p>
        <p>
          Contact for collaborations and both freelance and permanent work can
          be made at <b>artofelias.m@gmail.com</b>.
        </p>
      {/if}
      {#if selectedLanguage === 'PT'}
        <p>
          <b>Elias Martin</b> é um artista maranhense procurando trabalho na área
          de capas de livros e RPGs de mesa, com experiência prévia em quadrinhos,
          desenvolvimento visual e design de personagens. Ele é graduado em Artes
          Visuais e tem seus trabalhos publicados em antologias diversas, assim como
          histórias independentes publicadas online.
        </p>
        <p>
          Ele adora ficção especulativa, RPGs de mesa e tudo que é peculiar e
          estranho, cachorros também. Ele gosta de trabalhar em projetos
          narrativos e em funções voltadas aos detalhes.
        </p>
        <p>
          Para colaborações e trabalhos tanto freelancer como permanente,
          contato pode ser feito através do email <b>artofelias.m@gmail.com</b>.
        </p>
      {/if}
    </div>
  </div>

  <Footer showDdLogo />
</main>

<style lang="css" src="./styles.css"></style>
